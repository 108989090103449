import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import Content from '../core/styles/Content';
import Backoffice from '../core/sysext/CMS/containers/Backoffice';

const PagesPage: FC<PageProps> = ({ location }) => (
  <Backoffice location={location}>
    <Content>
      <h1>Calculatrice</h1>
      <p>{`Cette section est une section qui vous permettra de gagner du temps et de
    l'argent`}</p>
    </Content>
  </Backoffice>
);

export default PagesPage;
